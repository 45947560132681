@import '../../../scss/main.scss';

$content-width: 40px;

.wrapper-basic-layout {
  padding: 0 20px;

  @media not all and (hover: hover) {
    height: fit-content;
  }

  @media (max-width: $xs) {
    padding: 0 4px;
  }
}

.container-basic-layout {
  overflow: scroll;
  padding: 0 20px;
  width: #{$content-width}#{'%'};

  @media (max-width: $lg) {
    width: #{$content-width + 10}#{'%'};
  }

  @media (max-width: $md) {
    width: #{$content-width + 20}#{'%'};
  }

  @media (max-width: $sm) {
    width: #{$content-width + 40}#{'%'};
  }

  @media (max-width: $xs) {
    width: #{$content-width + 50}#{'%'};
  }
}

.title-basic-layout {
  padding-top: 28px;
  border-bottom: solid 1.5px $alice-blue;
  width: stretch;

  h1 {
    @include remove-margin(top);
    margin-bottom: 8px;
  }

  h4 {
    @include remove-margin;
  }

  .wrapper-basic-layout.without-border & {
    border-bottom: 0;
  }
}

.line-basic-layout {
  margin: 24px 0 !important;
  margin-bottom: 0;
  width: stretch;
  display: flex;
  flex-direction: column;

  p {
    margin-top: 0;
    margin-bottom: 16px;
  }

  .inline-action-button{
    flex: 1;
  }
}

.bottom-bar-basic-layout {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0px -4px 4px rgba(196, 196, 196, 0.15) !important;
  background-color: white;
  z-index: 3;

  button {
    height: 48px;
    margin: 16px 40px 24px;
  }

  @media (max-width: $sm) {
    padding-right: 0;
    justify-content: center;
    height: 104px;
  }
}
