@font-face {
  font-family: 'Proxima Nova';
  src: url('../../assets/Proxima Nova Thin.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../assets/Proxima Nova Light.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../assets/Proxima Nova Regular.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../assets/Proxima Nova Semibold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../assets/Proxima Nova Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../assets/Proxima Nova Extrabold.otf') format('opentype');
  font-weight: 800;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../assets/Proxima Nova Black.otf') format('opentype');
  font-weight: 900;
}
