// Light
$alice-blue: #dee5ec;

// Others
$salmon: #f37962;
$blue: #326478;
$blue-sapphire: #278ba5;

// Breakpoints
$xs: 320px;
$sm: 480px;
$md: 768px;
$lg: 960px;
