@import '../../../scss/main.scss';

.line-basic-layout {
  .MuiFormControlLabel-root {
    @include remove-margin(left, right);
  }
}

.checked,
.unchecked {
  @include input-padding;
  @include border(4px, 2px, solid, $alice-blue);
  padding-left: 16px;
  padding-right: 16px;
  width: inherit;
  margin-top: 8px;
  margin-bottom: 4px;
}

.checked {
  border-color: $blue-sapphire;
}
